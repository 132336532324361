'use client';

import React, { useState, useEffect, useRef } from 'react';

import Link from 'next/link';

const CopyrightBar = () => {
    const copyrightBarRef = useRef(null);

    const [animate, setAnimate] = useState(false);

    const handleHover = (e, action = 'leave') => {
        e.currentTarget.childNodes.forEach(child => {
            if (action === 'enter') {
                child.style.animationPlayState = 'paused';
            } else {
                child.style.animationPlayState = 'running';
            }
        });
    };

    useEffect(() => {
        if (copyrightBarRef.current) {
            const isScrollable = (element) => {
                return element.scrollWidth > element.clientWidth;
            };

            if (isScrollable(copyrightBarRef.current)) {
                setAnimate(true);
            }
        }
    }, [copyrightBarRef]);

    return (
        <div ref={copyrightBarRef} className='copyright bg-white-shadow mt-16 pt-4 pb-3 px-8 rounded-t-4xl max-w-full overflow-x-hidden flex gap-20 mr-5 xl:mr-0' onMouseEnter={(e) => handleHover(e, 'enter')} onMouseLeave={(e) => handleHover(e, 'leave')} onTouchStart={(e) => handleHover(e, 'enter')} onTouchEnd={(e) => handleHover(e, 'leave')}>
            {animate ? (
                <>
                    <p className='text-nowrap scroll-to-left'>
                        © Rioolservice van Egmond {new Date().getFullYear()} | Alle rechten voorbehouden | <Link href="/privacy-beleid">Privacybeleid</Link>
                    </p>
                    <p className='text-nowrap scroll-to-left'>
                        © Rioolservice van Egmond {new Date().getFullYear()} | Alle rechten voorbehouden | <Link href="/privacy-beleid">Privacybeleid</Link>
                    </p>
                </>
            ) : (
                <p className='text-nowrap'>
                    © Rioolservice van Egmond {new Date().getFullYear()} | Alle rechten voorbehouden | <Link href="/privacy-beleid">Privacybeleid</Link>
                </p>
            )}
        </div>
    );
}

export default CopyrightBar;